import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CacheService } from './services/cache/cache.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIconsModule } from '@ng-icons/core';
import { saxTrashOutline } from '@ng-icons/iconsax/outline';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAccordionModule,
    FontAwesomeModule,
    NgbModule,
    NgIconsModule.withIcons({
      saxTrashOutline,
    }),
  ],
  providers: [CacheService, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
